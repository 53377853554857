import React, { useState, useEffect, useRef, useContext } from 'react';
import { FaStar, FaRegHeart, FaHeart } from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import Logo from '../../assets/logo-bg.png';
import { searchResponse, getWishlist, removeFromWishlist, handleAddToWishlist } from "../../calls/auths";
import { AppContext } from '../../context/AppContext';
import { ToastComponent } from '../ToastComponent';

const YouMayLike = ({ addToWishListFunction, category, handleAddToCart, reviews, reviewsNum = 0, product, setAddToCartSuccessfully }) => {
    const [products, setProducts] = useState([]);
    const swiperRef = useRef();
    const { user, getCartCount, wishlist, setWishlist } = useContext(AppContext);
    const [wishlistItems, setWishlistItems] = useState([]);
    const [showWishlistToast, setShowWishlistToast] = useState(false);
    const [message, setMessage] = useState();
    const [showLoginToast, setShowLoginToast] = useState(false);
    const [quantities, setQuantities] = useState([]);

    async function getWishlistItems() {
        if (!user) return;
        const response = await getWishlist(user._id);
        if (response.status === 200 && response.data != null) {
            setWishlistItems(response.data.items.map(item => item.product));
        }
    }

    useEffect(() => {
        getWishlistItems();
    }, [user]);

    // Calculate average rating from reviews
    const calculateAverageRating = () => {
        const approvedReviews = reviews.filter(item => item.is_approved);
        if (approvedReviews.length === 0) return 0;
        
        const totalRating = approvedReviews.reduce((acc, review) => acc + review.rating, 0);
        const averageRating = totalRating / approvedReviews.length;
        
        // Round up to nearest whole number
        return Math.ceil(averageRating);
    };

    async function toggleWishlist(user, product) {
        if (!user) {
            setShowLoginToast(true);
            return;
        }

        if (wishlistItems.includes(product._id)) {
            const userId = user._id;
            const productId = product._id;

            const response = await removeFromWishlist({ userId, productId });
            if (response.status === 200) {
                setWishlist(wishlist - 1);
                setMessage('Removed from wishlist.');
                setShowWishlistToast(true);
                getWishlistItems(); // Update wishlist items after removing
            }
        } else {
            const response = await handleAddToWishlist(user, product);
            if (response.status === 200) {
                setWishlist(wishlist + 1);
                setMessage('Added to wishlist.');
                setShowWishlistToast(true);
                getWishlistItems(); // Update wishlist items after adding
            }
        }
    }

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await searchResponse({ category });
                if (response.status === 200) {
                    setProducts(response.data);
                    setQuantities(response.data.map(() => 1)); // Initialize quantities state
                }
            } catch (error) {
                console.error("Failed to fetch products", error);
            }
        };

        if (category) {
            fetchProducts();
        }
    }, [category]);

    async function addToCartFunction(qty) {
        try {
            if (user) {
                const response = await handleAddToCart(user, product.measurements[0]._id, product.measurements[0].colors[0]._id, qty);
    
                if (response && response.status === 200) {
                    getCartCount();
                    setAddToCartSuccessfully(true);
                } else {
                    console.error('Failed to add to cart:', response);
                    // Optionally handle the failed API response here
                }
            } else {
                const cart = JSON.parse(localStorage.getItem('cart')) || [];
                const existingProductIndex = cart.findIndex(item => item.colorId === product.measurements[0].colors[0]._id);

                if (existingProductIndex !== -1) {
                    // Product exists in the cart, update the quantity
                    cart[existingProductIndex].quantity = qty;
                } else {
                    // Product does not exist in the cart, add new item
                    cart.push({ colorId: product.measurements[0].colors[0]._id, productId: product.measurements[0]._id, quantity: qty });
                }

                localStorage.setItem('cart', JSON.stringify(cart));
                console.log('Product added to cart in local storage');
                getCartCount()
                setAddToCartSuccessfully(true);
            }
        } catch (error) {
            console.error('Error adding to cart:', error);
            // Handle network error or show error message
        }
    }

    const handleQuantityChange = (index, change) => {
        setQuantities((prevQuantities) => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = Math.max(1, newQuantities[index] + change); // Ensure quantity doesn't go below 1
            return newQuantities;
        });
    };

    return (
        <div className="container py-4">
            {showLoginToast && <ToastComponent warning message={'Please log in to add to wishlist.'} onClose={() => setShowLoginToast(false)} />}
            {showWishlistToast && <ToastComponent success message={message} onClose={() => setShowWishlistToast(false)} />}
            <div className="text-xl font-semibold text-center mb-2">You May Also Like</div>
            <div className="w-full">
                <Swiper
                    slidesPerView={"auto"}
                    loop
                    breakpoints={{
                        320: { slidesPerView: 1 },
                        640: { slidesPerView: 2 },
                        768: { slidesPerView: 2 },
                        1024: { slidesPerView: 3 },
                        1280: { slidesPerView: 4 },
                    }}
                    modules={[Autoplay, Navigation]}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                >
                    {products.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="m-2 bg-white group hover:shadow-[0_0_5px_1px_rgba(0,0,0,0.2)] relative flex flex-col rounded overflow-hidden">
                                <div className="relative w-full aspect-[3/2] overflow-hidden">
                                    <a href={`/product/${item.name}`} className="w-full h-full">
                                        <img src={item.images[0] ?? Logo} className='w-full h-full object-cover' alt="" />
                                    </a>
                                    <div
                                        title='add to wishlist'
                                        className={`opacity-0 cursor-pointer group-hover:opacity-100 transition duration-500 absolute top-4 right-4 rounded-full w-[40px] aspect-square border-[2px] border-primary flex justify-center items-center ${wishlistItems.includes(item._id) ? 'text-red-500' : 'text-primary'} hover:bg-primary hover:text-white`}
                                        onClick={() => toggleWishlist(user, item)}
                                    >
                                        {wishlistItems.includes(item._id) ? <FaHeart /> : <FaRegHeart />}
                                    </div>
                                </div>
                                <div className="text-center py-4 px-2">
                                    <div className="text-xs text-gray-400 font-semibold">{item.brand}</div>
                                    <a href={`/product/${item.name}`} className="text-sm font-semibold hover:text-primary">{item.name}</a>
                                    <div className="text-sm font-semibold"><span className='text-primary'>&#8358;{item.measurements[0].price.toLocaleString()}</span></div>
                                    <div className="text-xs flex items-center gap-2 justify-center mt-2 flex-wrap *:text-nowrap">
                                        <div className="flex gap-1 items-center">
                                        {[...Array(calculateAverageRating())].map((_, index) => (
                                            <div key={index} className="text-yellow-500">
                                                <FaStar />
                                            </div>
                                        ))}
                                        {[...Array(5 - calculateAverageRating())].map((_, index) => (
                                            <div key={index} className="text-gray-400">
                                                <FaStar />
                                            </div>
                                        ))}
                                        </div>
                                        <div className="text-gray-400">({reviews.filter(item => item.is_approved).length} Review{reviewsNum > 1 ? 's' : ''})</div>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center gap-2 my-2">
                                    <button
                                        className="text-xs bg-gray-200 hover:bg-gray-300 rounded px-2 py-1"
                                        onClick={() => handleQuantityChange(index, -1)}
                                    >
                                        -
                                    </button>
                                    <span>{quantities[index]}</span>
                                    <button
                                        className="text-xs bg-gray-200 hover:bg-gray-300 rounded px-2 py-1"
                                        onClick={() => handleQuantityChange(index, 1)}
                                    >
                                        +
                                    </button>
                                </div>
                                <div
                                    className="cursor-pointer w-full bg-primary hover:bg-tertiary hover:text-primary rounded flex items-center text-xs gap-2 text-white py-2 justify-center"
                                    onClick={() => addToCartFunction(quantities[index])}
                                >
                                    <MdOutlineAddShoppingCart />
                                    <span>ADD TO CART</span>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                    <div onClick={() => swiperRef.current?.slidePrev()} className="z-10 text-white absolute left-2 top-[50%] -translate-y-[50%] w-[40px] aspect-[1/2] bg-secondary/15 flex justify-center items-center text-2xl cursor-pointer hover:text-primary">
                        <BsChevronLeft />
                    </div>
                    <div onClick={() => swiperRef.current?.slideNext()} className="z-10 text-white absolute right-2 top-[50%] -translate-y-[50%] w-[40px] aspect-[1/2] bg-secondary/15 flex justify-center items-center text-2xl cursor-pointer hover:text-primary">
                        <BsChevronRight />
                    </div>
                </Swiper>
            </div>
        </div>
    );
};

export default YouMayLike;
