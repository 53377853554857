import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FaBars, FaTimes } from 'react-icons/fa';
import { BiFilter } from "react-icons/bi";
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllSizes, getCategories, getProducts, searchResponse } from '../calls/auths';

// Define the different categories for each dropdown
const sortOptions = [
  { value: 'default', label: 'Price: All' },
  { value: 'priceAsc', label: 'Price: Low to High' },
  { value: 'priceDesc', label: 'Price: High to Low' },
  { value: 'newest', label: 'Newest' },
  { value: 'rating', label: 'Best Rating' }
];

const newInDateOptions = [
  { value: 'default', label: 'New in date' },
  { value: 'last24Hours', label: 'Last 24 Hours' },
  { value: 'last7Days', label: 'Last 7 Days' },
  { value: 'last30Days', label: 'Last 30 Days' }
];

const sizeOptions = [
  { value: 'default', label: 'Size: All' },
  { value: 'xs', label: 'Extra Small' },
  { value: 's', label: 'Small' },
  { value: 'm', label: 'Medium' },
  { value: 'l', label: 'Large' },
  { value: 'xl', label: 'Extra Large' },
  { value: 'xxl', label: 'Extra Extra Large' },
  { value: 'xxxl', label: 'Extra Extra Extra Large' }
];

const brandOptions = [
  { value: 'default', label: 'Brand: All' },
  { value: 'nike', label: 'Nike' },
  { value: 'adidas', label: 'Adidas' },
  { value: 'puma', label: 'Puma' },
  { value: 'reebok', label: 'Reebok' }
];

const colourOptions = [
  { value: 'default', label: 'Colour: All' },
  { value: 'black', label: 'Black' },
  { value: 'white', label: 'White' },
  { value: 'red', label: 'Red' },
  { value: 'blue', label: 'Blue' }
];

const priceRangeOptions = [
  { value: 'default', label: 'Price Range: All' },
  { value: '150000-200000', label: '#150,000 - #200,000' },
  { value: '200000-300000', label: '#200,000 - #300,000' },
  { value: '300000-350000', label: '#300,000 - #350,000' },
  { value: '400000+', label: '#400,000+' }
];

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999
  })
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const options = {
    sort: sortOptions,
    newInDate: newInDateOptions,
    size: sizeOptions,
    category: categories,
    measurement_type: productTypes,
    brand: brandOptions,
    color: colourOptions,
    priceRange: priceRangeOptions
  };

  const handleChange = (selectedOption, field) => {
    const currentParams = new URLSearchParams(location.search);
    
    if (field === 'priceRange' && selectedOption.value !== 'default') {
      const [minPrice, maxPrice] = selectedOption.value.split('-');
      currentParams.set('min_price', minPrice);
      if (maxPrice) {
        currentParams.set('max_price', maxPrice);
      } else {
        currentParams.delete('max_price');
      }
    } else if (selectedOption.value === 'default') {
      currentParams.delete(field); // Remove the parameter if default is selected
    } else {
      currentParams.set(field, selectedOption.value); // Set the new parameter
    }

    // Convert URLSearchParams to object
    const params = Object.fromEntries(currentParams.entries());
    
    searchResponse(params); // Assuming searchResponse handles the API call

    // Navigate to the updated URL
    navigate(`?${new URLSearchParams(params).toString()}`, { replace: true });
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  async function Categories() {
    const response = await getCategories();
    let fetchedCategories = response.data.map(category => ({ value: category.name, label: category.name }));

    // Filter out the "Uncategorized" category
    fetchedCategories = fetchedCategories.filter(category => category.value !== 'Uncategorized');

    setCategories([{ value: 'default', label: 'Category: All' }, ...fetchedCategories]);
  }

  async function Sizes() {
    const response = await getAllSizes();
    const fetchedProductTypes = response.data.map(type => ({ value: type.name, label: type.name }));
    setProductTypes([{ value: 'default', label: 'Product Type: All' }, ...fetchedProductTypes]);
  }

  async function Products() {
    const response = await getProducts();
    // console.log(response.data);
  }

  useEffect(() => {
    Categories();
    Sizes();
    Products();
  }, []);

  return (
    <div className="bg-gray-200 px-4 py-2">
      <div className="flex flex-col items-center justify-between md:justify-center">
        <div className='flex px-36'>
          <h1 className='md:hidden font-semibold py-2'>Filter</h1>
          <button
            className="md:hidden text-2xl"
            onClick={toggleMenu}
          >
            {isOpen ? <FaTimes /> : <BiFilter />}
          </button>
        </div>
        <div className="hidden md:flex gap-4 justify-center">
          {Object.keys(options).map((key) => (
            <div className="w-48" key={key}>
              <CreatableSelect
                options={options[key]}
                onChange={(selectedOption) => handleChange(selectedOption, key)}
                placeholder={options[key][0]?.label}
                isSearchable
                styles={customStyles}
              />
            </div>
          ))}
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden grid grid-cols-2 gap-4 mt-4">
          {Object.keys(options).map((key) => (
            <div className="m-2 w-48" key={key}>
              <CreatableSelect
                options={options[key]}
                onChange={(selectedOption) => handleChange(selectedOption, key)}
                placeholder={options[key][0].label}
                isSearchable
                styles={customStyles}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Header;
