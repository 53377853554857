import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "./screens/admin/AdminDashboard";
import { useContext, useEffect, useState } from "react";
import AdminNavs from "./components/admin/AdminNavs";
import Header from "./components/Header";
import AddProduct from "./screens/admin/AddProduct";
import AddCategory from "./screens/admin/AddCategory";
import Sizes from "./screens/admin/Sizes";
import Coupons from "./screens/admin/Coupons";
import { AppContext } from "./context/AppContext";
import Users from "./screens/admin/Users";
import AddSalesRep from "./screens/admin/AddSalesRep";
import ProductCategory from "./screens/admin/ProductCategories";
import Receipts from "./screens/admin/Receipts";
import SalesRecords from "./screens/admin/SalesDetails";
import Settings from "./screens/Settings";
import Backend from "./screens/admin/BAckends";
import Pos from "./screens/admin/Pos";
import Ads from "./screens/admin/AddStcickyAds";
import CustomersLoyalty from "./screens/admin/CustomersLoyalty";
import ZaddyList from "./screens/admin/ZaddyList"
import Inventory from "./screens/admin/Inventory";
import {jwtDecode} from "jwt-decode";
import Order from "./screens/admin/Order";
import PausedSales from "./screens/admin/PausedSales";
import Dispute from "./screens/admin/Dispute";
import Pages from "./screens/admin/Pages";

const GetPausedSaleComponent = () => {
    const { orderNumber } = useParams();
    const getPausedSale = (orderNumber) => {
        return JSON.parse(localStorage.getItem(`pausedSale-${orderNumber}`));
    };
    const pausedSale = getPausedSale(orderNumber);
    return <Pos pausedSale={pausedSale} />;
};

const Admin = () => {
    const { setLoggedIn } = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [headerTitle, setHeaderTitle] = useState('');
    const [expandedNav, setExpandedNav] = useState(false);

    const handleTitleChange = () => {
        switch (location.pathname) {
            case '/admin/dashboard':
                setHeaderTitle('Dashboard');
                break;
            case '/admin/pos':
                setHeaderTitle('POS');
                break;
            case '/admin/sales':
                setHeaderTitle('Order List');
                break;
            case '/admin/receipts':
                setHeaderTitle('Receipts');
                break;
            case '/admin/sales_record':
                setHeaderTitle('Sales Record');
                break;
            case '/admin/dispute':
                setHeaderTitle('Product Dispute');
                break;
            case '/admin/adverts':
                setHeaderTitle('Advert Promo');
                break;
            case '/admin/add_product':
                setHeaderTitle('Add Product');
                break;
            case '/admin/add_category':
                setHeaderTitle('Add Category');
                break;
            case '/admin/fashion_categories':
                setHeaderTitle('Landing Page Fashion Category');
                break;
            case '/admin/sizes':
                setHeaderTitle('Sizes');
                break;
            case '/admin/coupons':
                setHeaderTitle('Coupons');
                break;
            case '/admin/inventory':
                setHeaderTitle('Inventory');
                break;
            case '/admin/customers':
                setHeaderTitle('Customers Loyalty Point');
                break;
            case '/admin/add_sales_rep':
                setHeaderTitle('Add Sales Rep');
                break;
            case '/admin/paused-sales':
                setHeaderTitle('Pending Sales');
                break;
            case '/admin/users':
                setHeaderTitle('Users');
                break;
            case '/admin/zaddylist':
                setHeaderTitle('Zaddy Pay');
                    break;
            case '/admin/settings':
                setHeaderTitle('Settings');
                break;
            case '/admin/backends':
                setHeaderTitle('Admin Backend');
                break;
            case '/admin/pages':
                setHeaderTitle('Admin Pages Setup');
                break;
            default:
                if (location.pathname.includes('/admin/inventory/detail/')) {
                    setHeaderTitle('Inventory > Detail');
                } else if (location.pathname.includes('/admin/inventory/edit/')) {
                    setHeaderTitle('Inventory > Edit');
                } else {
                    setHeaderTitle('Admin');
                }
                break;
        }
    };

    useEffect(() => {
        const handleLogin = async () => {
            const loginSession = window.localStorage.getItem("tripstore_login");

            if (loginSession && loginSession !== "") {
                const userType = jwtDecode(loginSession);
                const isUser = userType.user_type === "user";
                const isSalesRep = userType.user_type === "sales_rep";
                if (isUser) {
                    Navigate("/user/dashboard");
                } else if (isSalesRep) {
                    navigate("/sales_rep/dashboard");
                }
            } else {
                navigate('/login');
            }
        };

        handleLogin();
        handleTitleChange();
    }, [location.pathname]);

    return (
        <div className="w-full h-screen md:flex relative">
            <AdminNavs expandedNav={expandedNav} setExpandedNav={setExpandedNav} />
            <div onClick={() => setExpandedNav(false)} className={`${expandedNav ? "block" : "hidden"} md:hidden fixed w-screen h-screen z-10`}></div>
            <div className="h-full overflow-y-auto overflow-x-hidden md:flex-1 scrollbar relative">
                <Header setExpandedNav={setExpandedNav} title={headerTitle} setLoggedIn={setLoggedIn} />
                <Routes>
                    <Route element={<AdminDashboard />} path="/dashboard" />
                    <Route element={<Pos />} path="/pos" />
                    <Route element={<Order />} path="/sales" />
                    <Route element={<PausedSales />} path="/paused-sales/*" />
                    <Route element={<GetPausedSaleComponent />} path="/paused-sales/:orderNumber" />
                    <Route element={<Receipts />} path="/receipts" />
                    <Route element={<SalesRecords />} path="/sales_record" />
                    <Route element={<Dispute />} path="/dispute" />
                    <Route element={<AddProduct />} path="/add_product" />
                    <Route element={<AddCategory />} path="/add_category" />
                    <Route element={<ProductCategory />} path="/fashion_categories" />
                    <Route element={<Sizes />} path="/sizes" />
                    <Route element={<Coupons />} path="/coupons" />
                    <Route element={<Ads />} path="/adverts" />
                    <Route element={<ZaddyList />} path="/zaddylist" />
                    <Route element={<Pages />} path="/pages" />
                    <Route element={<CustomersLoyalty />} path="/customers" />
                    <Route element={<Inventory />} path="/inventory/*" />
                    <Route element={<Backend />} path="/backends/*" />
                    <Route element={<AddSalesRep />} path="/add_sales_rep" />
                    <Route element={<Users />} path="/users" />
                    <Route element={<Settings userType={'Admin'} />} path="/settings" />
                </Routes>
            </div>
        </div>
    );
};

export default Admin;
