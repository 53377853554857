import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";
import BaseSticky from "../components/product/BaseSticky";
import Top from "../components/top";
import { useState, useEffect, useContext } from "react";
import DisplaySpec from "../components/product/DisplaySpec";
import InfoTabs from "../components/product/InfoTabs";
import YouMayLike from "../components/product/YouMayLike";
import { useParams } from "react-router-dom";
import { searchResponse, handleAddToWishlist, handleAddToCart, removeFromWishlist } from "../calls/auths";
import Loader from "../calls/Loader";
import MessageResponse from "../calls/MessageResponse";
import { AppContext } from "../context/AppContext";
import { ToastComponent } from "../components/ToastComponent";

const Product = () => {
    const { user, getCartCount } = useContext(AppContext)

    const { productName } = useParams();
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [qty, setQty] = useState(1);
    const [reviews, setReviews] = useState([]);
    const { isInWishlist, setIsInWishlist, setWishlist, wishlist } = useContext(AppContext);
    const [addedToWishlistToast, setAddedToWishlistToast] = useState(false);
    const [removedFromWishlistToast, setRemovedFromWishlistToast] = useState(false);
    const [showLoginToast, setShowLoginToast] = useState(false);
    const [addToCartSuccessfully, setAddToCartSuccessfully] = useState(false);
    const [price, setPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [productVariantId, setProductVariantId] = useState();
    const [colorId, setColorId] = useState(null);
    const [currentSize, setCurrentSize] = useState("");
    const [selectedColor, setSelectedColor] = useState(null);
    const [stockAmount, setStockAmount] = useState(null);
    const [outOfStock, setoutOfStock] = useState(null);
    
    useEffect(() => {
        setoutOfStock(stockAmount <= 0); //this will return true or false, and then set out of stock to be true or false
    }, [stockAmount])

    useEffect(() => {
        setStockAmount(null); // when the user changes the size reset the stock amount to null
    }, [price])
    
    useEffect(() => {
        const fetchProduct = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await searchResponse({ name: productName });
                if (response.status === 200 && response.data.length > 0) {
                    setProduct(response.data[0]);
                    setPrice(response.data[0].measurements[0].price);
                    setReviews(response.data[0].reviews)
                } else {
                    setError("Unable to fetch Product");
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchProduct();
    }, [productName]);

    async function addToWishListFunction(user, product) {
        if (!user) {
            setShowLoginToast(true);
            return;
        }

        const response = await handleAddToWishlist(user, product);
        
        if(response.status === 200) {
            setWishlist(wishlist + 1);
            setIsInWishlist(true);
            setAddedToWishlistToast(true)
        }
    }

    async function removefromWishListFunction(user, product) {
        if (!user) {
            setShowLoginToast(true);
            return;
        }

        const userId = user._id;
        const productId = product._id;

        const response = await removeFromWishlist({ userId, productId });
        
        if(response.status === 200) {
            setWishlist(wishlist - 1);
            setIsInWishlist(false);
            setRemovedFromWishlistToast(true)
        }
    }

    async function addToCartFunction() {
        if(currentSize === "") {
            alert('Select a size');
            return;
        }
        if(colorId === null) {
            alert('Select a color');
            return;
        }

        try {
            if (user) {
                const response = await handleAddToCart(user, productVariantId, colorId, qty);
    
                if (response && response.status === 200) {
                    getCartCount();
                    setAddToCartSuccessfully(true);
                } else {
                    console.error('Failed to add to cart:', response);
                    // Optionally handle the failed API response here
                }
            } else {
                const cart = JSON.parse(localStorage.getItem('cart')) || [];
                const existingProductIndex = cart.findIndex(item => item.colorId === colorId);

                if (existingProductIndex !== -1) {
                    // Product exists in the cart, update the quantity
                    cart[existingProductIndex].quantity = qty;
                } else {
                    // Product does not exist in the cart, add new item
                    cart.push({ colorId: colorId, productId: productVariantId, quantity: qty });
                }

                localStorage.setItem('cart', JSON.stringify(cart));
                console.log('Product added to cart in local storage');
                getCartCount()
                setAddToCartSuccessfully(true);
            }
        } catch (error) {
            console.error('Error adding to cart:', error);
            // Handle network error or show error message
        }
    }

    if (isLoading) return <Loader />;
    if (error) return <MessageResponse msg={{ response: { status: 500, data: "1" }, msg: "Cannot Fetch Product" }} />;
    if (!product) return null;

    return (
        <div className="relative w-full min-h-screen">
            <Top />
            <DisplaySpec
                setPrice={setPrice}
                setDiscount={setDiscount}
                discount={discount}
                img={product.images || []}
                title={product.name}
                reviews={reviews || 0}
                reviewsNum={reviews.length} // Display actual length of reviews fetched
                price={price}
                qty={qty}
                setQty={setQty}
                category={product.category || ""}
                sizes={product.measurements || []}
                color={product.color || ""}
                handleAddToCart={handleAddToCart}
                handleAddToWishlist={handleAddToWishlist}
                product={product}
                setProductVariantId={setProductVariantId}
                productVariantId={productVariantId}
                addToCartFunction={addToCartFunction}
                isInWishlist={isInWishlist} 
                setIsInWishlist={setIsInWishlist}
                addToWishListFunction={addToWishListFunction}
                removefromWishListFunction={removefromWishListFunction}
                setAddToCartSuccessfully={setAddToCartSuccessfully}
                currentSize={currentSize}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                setCurrentSize={setCurrentSize}
                setColorId={setColorId}
                stockAmount={stockAmount}
                setStockAmount={setStockAmount}
                outOfStock={outOfStock}
                colorId={colorId}
            />
            <InfoTabs
                productId={product._id}
                description={product.description || ""}
                shippingInformation={product.shippingInformation || ""}
                additionalInformation={product.additionalInformation || ""}
                reviews={reviews} // Pass reviews data to InfoTabs component
                setReviews={setReviews} // Pass reviews setter
            />
            <YouMayLike handleAddToCart={handleAddToCart} removefromWishListFunction={removefromWishListFunction} addToWishListFunction={addToWishListFunction} addToCartSuccessfully={addToCartSuccessfully} setAddToCartSuccessfully={setAddToCartSuccessfully} category={product.category || ""} reviews={reviews || 0}
                reviewsNum={reviews.length} product={product} />
            <Footer />
            <div className="hidden md:block h-[80px]"></div>
            <BaseSticky
                setPrice={setPrice}
                setDiscount={setDiscount}
                discount={discount}
                img={product.images ? product.images[0] : ""}
                title={product.name}
                price={price}
                qty={qty}
                setQty={setQty}
                handleAddToCart={handleAddToCart}
                handleAddToWishlist={handleAddToWishlist}
                product={product}
                isInWishlist={isInWishlist} 
                setIsInWishlist={setIsInWishlist}
                addToWishListFunction={addToWishListFunction}
                removefromWishListFunction={removefromWishListFunction}
                addToCartFunction={addToCartFunction}
                outOfStock={outOfStock}
                colorId={colorId}
            />
            <ScrollUpBtn />
            {showLoginToast && <ToastComponent warning message={'Please log in to add to wishlist.'} onClose={() => setShowLoginToast(false)} />}
            {addedToWishlistToast && <ToastComponent success message={'Added to wishlist.'} onClose={() => setAddedToWishlistToast(false)} />}
            {removedFromWishlistToast && <ToastComponent success message={'Removed from wishlist'} onClose={() => setRemovedFromWishlistToast(false)} />}
            {addToCartSuccessfully && <ToastComponent success message={'Added to cart'} onClose={() => setAddToCartSuccessfully(false)} />}
            
        </div>
    );
};

export default Product;
