import React from 'react';

const MessageResponse = ({ response, msg }) => {
  // eslint-disable-next-line no-unused-vars
  const { data, status } = response;
  const isSuccess = status === 200 || status === 201;
  const messageStyle = {
    color: isSuccess ? '#12da12' : '#ff4f4f',
    border: `1px solid ${isSuccess ? 'green' : 'red'}`,
    padding: '10px',
    backgroundColor: isSuccess ? "#60ff0029" : "#ff000029",
    borderRadius: '7px',
    margin: '10px 0'
  };

  return (
    <div style={messageStyle}>
      <p>{msg}</p>
    </div>
  );
};

export default MessageResponse;