import React, { useRef, useContext, useState, useEffect } from 'react';
import { FaStar, FaRegHeart, FaHeart } from "react-icons/fa";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/logo-bg.png';
import { AppContext } from '../../context/AppContext';
import { handleAddToWishlist, handleAddToCart, removeFromWishlist, getWishlist } from '../../calls/auths'; // Import the API functions
import { ToastComponent } from "../ToastComponent";

const ProductList = ({ products }) => {
    const location = useLocation();
    const loaderRef = useRef(null);

    const { user, getCartCount, cart, wishlist, setWishlist } = useContext(AppContext);

    const [showWishlistToast, setShowWishlistToast] = useState(false);
    const [showLoginToast, setShowLoginToast] = useState(false);
    const [addToCartSuccessfully, setAddToCartSuccessfully] = useState(false);
    const [wishlistItems, setWishlistItems] = useState([]);
    const [message, setMessage] = useState();
    const [quantities, setQuantities] = useState(products.map(() => 1)); // Initialize quantities state

    const getSortFunction = (sortParam) => {
        switch (sortParam) {
            case 'priceAsc':
                return (a, b) => a.measurements[0].price - b.measurements[0].price;
            case 'priceDesc':
                return (a, b) => b.measurements[0].price - a.measurements[0].price;
            case 'newest':
                return (a, b) => new Date(b.createdAt) - new Date(a.createdAt);
            case 'rating':
                return (a, b) => b.rating - a.rating;
            default:
                return (a, b) => a.id - b.id; // Default sort (by ID or other default parameter)
        }
    };

    // Calculate the total amount of colors for each product
    const getTotalAmount = (product) => {
        return product.measurements.reduce((total, measurement) => {
            return total + measurement.colors.reduce((sum, color) => sum + color.amount, 0);
        }, 0);
    };

    // Filter out products where the total amount is zero
    const filteredProducts = products.filter(product => getTotalAmount(product) > 0);

    const sortedProducts = [...filteredProducts];
    const params = new URLSearchParams(location.search);
    const sortParam = params.get('sort');
    if (sortParam) {
        sortedProducts.sort(getSortFunction(sortParam));
    }

    async function getWishlistItems() {
        if (!user) return;
        const response = await getWishlist(user._id);
        if (response.status === 200 && response.data != null) {
            setWishlistItems(response.data.items.map(item => item.product));
        }
    }

    useEffect(() => {
        getWishlistItems();
    }, [user]);

    async function addToCart(product, quantity) {
        try {
            const response = await handleAddToCart(user, product.measurements[0]._id, product.measurements[0].colors[0]._id, quantity);
            
            if (response && response.status === 200) {
                getCartCount();
                setAddToCartSuccessfully(true);
            } else {
                getCartCount(); //add +1 to the cart
                setAddToCartSuccessfully(true); //because it will still be added to cart but saved in localstorage
            }
        } catch (error) {
            console.error('Error adding to cart:', error);
            // Handle network error or show error message
        }
    }

    async function toggleWishlist(user, product) {
        if (!user) {
            setShowLoginToast(true);
            return;
        }

        if (wishlistItems.includes(product._id)) {
            const userId = user._id;
            const productId = product._id;

            const response = await removeFromWishlist({ userId, productId });
            if (response.status === 200) {
                setWishlist(wishlist - 1);
                setMessage('Removed from wishlist.');
                setShowWishlistToast(true);
                getWishlistItems(); // Update wishlist items after removing
            }
        } else {
            const response = await handleAddToWishlist(user, product);
            if (response.status === 200) {
                setWishlist(wishlist + 1);
                setMessage('Added to wishlist.');
                setShowWishlistToast(true);
                getWishlistItems(); // Update wishlist items after adding
            }
        }
    }

    const handleQuantityChange = (index, change) => {
        setQuantities((prevQuantities) => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = Math.max(1, newQuantities[index] + change); // Ensure quantity doesn't go below 1
            return newQuantities;
        });
    };

    // Calculate average rating from reviews
    const calculateAverageRating = (reviews) => {
        const approvedReviews = reviews.filter(item => item.is_approved);
        if (approvedReviews.length === 0) return 0;
        
        const totalRating = approvedReviews.reduce((acc, review) => acc + review.rating, 0);
        const averageRating = totalRating / approvedReviews.length;
        
        // Round up to nearest whole number
        return Math.ceil(averageRating);
    };

    console.log(sortedProducts);

    return (
        <div className="bg-gray-200 p-4">
            {showLoginToast && <ToastComponent warning message={'Please log in to add to wishlist.'} onClose={() => setShowLoginToast(false)} />}
            {showWishlistToast && <ToastComponent success message={message} onClose={() => setShowWishlistToast(false)} />}
            {addToCartSuccessfully && <ToastComponent success message={'Added to cart'} onClose={() => setAddToCartSuccessfully(false)} />}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {sortedProducts.map((item, index) => (
                    <div className="m-2 bg-white group hover:shadow-[0_0_5px_1px_rgba(0,0,0,0.2)] relative flex flex-col rounded overflow-hidden" key={index}>
                        <div className="relative w-full h-[350px] overflow-hidden">
                            <a href={`/product/${item.name}`} className="w-full h-full">
                                <img src={item.images[0] ?? Logo} className='w-full h-full object-cover object-top' alt="" />
                            </a>
                            <div
                                title='add to wishlist'
                                className={`opacity-0 cursor-pointer group-hover:opacity-100 transition duration-500 absolute top-4 right-4 rounded-full w-[40px] aspect-square border-[2px] border-primary flex justify-center items-center ${wishlistItems.includes(item._id) ? 'text-red-500' : 'text-primary'} hover:bg-primary hover:text-white`}
                                onClick={() => toggleWishlist(user, item)}
                            >
                                {wishlistItems.includes(item._id) ? <FaHeart /> : <FaRegHeart />}
                            </div>
                        </div>
                        <div className="text-center py-4 px-2">
                            <div className="mb-2">
                                <div
                                    className="transition duration-500 cursor-pointer w-[100%] bg-secondary hover:bg-secondary hover:text-white rounded flex items-center text-xs gap-2 text-white py-2 justify-center"
                                    onClick={() => addToCart(products[index], quantities[index])}
                                >
                                    <MdOutlineAddShoppingCart />
                                    <span>ADD TO CART</span>
                                </div>
                                <div className="flex justify-center items-center gap-2 mt-2">
                                    <button
                                        className="text-xs bg-gray-200 hover:bg-gray-300 rounded px-2 py-1"
                                        onClick={() => handleQuantityChange(index, -1)}
                                    >
                                        -
                                    </button>
                                    <span>{quantities[index]}</span>
                                    <button
                                        className="text-xs bg-gray-200 hover:bg-gray-300 rounded px-2 py-1"
                                        onClick={() => handleQuantityChange(index, 1)}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            <div className="text-xs text-gray-400 font-semibold">{item.brand}</div>
                            <a href={`/product/${item.name}`} className="text-sm font-semibold hover:text-secondary">{item.name}</a>
                            <div className="text-sm font-semibold">
                                {item.measurements[0].discount ? (
                                    <span className='text-secondary'>
                                        <span style={{ textDecoration: 'line-through' }}>&#8358;{item.measurements[0].price.toLocaleString()}</span>
                                        {' '}
                                        &#8358;{(item.measurements[0].price - (item.measurements[0].price * (item.measurements[0].discount / 100))).toLocaleString()}
                                        <p className='text-green-500'>{item.measurements[0].discount}% OFF!</p>
                                    </span>
                                ) : (
                                    <span className='text-secondary'>&#8358;{item.measurements[0].price.toLocaleString()}</span>
                                )}
                            </div>

                            <div className="text-xs flex items-center gap-2 justify-center mt-2 flex-wrap">
                                <div className="flex gap-1 items-center">
                                    {[...Array(calculateAverageRating(sortedProducts[index].reviews))].map((_, index) => (
                                        <div key={index} className="text-yellow-500">
                                            <FaStar />
                                        </div>
                                    ))}
                                    {[...Array(5 - calculateAverageRating(sortedProducts[index].reviews))].map((_, index) => (
                                        <div key={index} className="text-gray-400">
                                            <FaStar />
                                        </div>
                                    ))}
                                </div>
                                <div className="text-gray-400">({item.reviews.filter(item => item.is_approved).length} Reviews)</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {products.length === 0 && (
                <div className="flex justify-center items-center">
                    <span>No products found.</span>
                </div>
            )}
            </div>
    );
}

export default ProductList;
