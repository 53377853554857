import React, { useEffect, useRef, useState } from 'react';
import { FaArrowRightLong } from "react-icons/fa6";
import { getAllProductCategoriesContent } from '../calls/auths'; // Adjust the import based on your file structure

const Category = () => {
  const [products, setProducts] = useState([]);

  const observer = useRef(null);

  useEffect(() => {
    // Fetch the category data from the backend
    const fetchCategories = async () => {
      try {
        const response = await getAllProductCategoriesContent();
        if (response.status === 200) {
          setProducts(response.data); // Assuming response.data is an array of categories
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', "error");
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slideIn');
        }
      });
    }, { threshold: 0.1 });

    const elements = document.querySelectorAll('.animate-item');
    elements.forEach((element) => observer.current.observe(element));

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [products]); // Re-run when products change

  return (
    <div className="flex flex-col items-center bg-gray-300/70 py-6 px-5">
      <div>
        <h1 className="text-3xl font-semibold mb-3 uppercase">Fashion Categories</h1>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 lg:gap-12 p-2">
        {products.map((product, index) => (
          <div
            key={index}
            className="w-full md:w-[280px] lg:w-[420px] h-80 md:h-96 lg:h-[640px] bg-cover bg-center p-8 md:p-10 rounded opacity-0 animate-item"
            style={{ backgroundImage: `url(${product.image})` }} // Use the dynamic image URL
          >
            <div className="mt-32 md:mt-52 lg:mt-60">
              <div className="bg-gray-300/80 text-center rounded-md">
                <h1 className="text-sm md:text-lg font-semibold px-4">{product.title}</h1>
                <h1 className="text-sm md:text-lg">{product.offerText}</h1>
              </div>
              <a href={product.link} className="mb-3 md:mb-1.5 mt-2 py-2 md:py-1 px-1.5 md:px-4 bg-white/50 backdrop-blur-sm hover:bg-primary rounded text-sm text-gray-700 flex items-center justify-center gap-2 uppercase group/btn">
                <span>Shop Now</span>
                <span className="max-w-0 group-hover/btn:max-w-[30px] overflow-hidden transition-all duration-500">
                  <FaArrowRightLong />
                </span>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
