import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ScrollUpBtn from "../components/ScrollUpBtn";
import CategoryProduct from "../components/CategoryProduct";
import Top from "../components/top";
import Heads from "../components/Heads";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import StickyTop from "../components/home/StickyTopHome";
import { searchResponse } from '../calls/auths';

const Search = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const queryObject = {};
  params.forEach((value, key) => {
    queryObject[key] = value;
  });

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getProducts = async (query) => {
    setLoading(true);
    try {
      const response = await searchResponse(query);
      if (response.status === 200) {
        setProducts(response.data);
      } else {
        console.error("Failed to fetch products:", response.data);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryString = JSON.stringify(queryObject);
    getProducts(queryObject);
  }, [location.search]); // Using location.search as the dependency

  return (
    <div className="relative w-full min-h-screen">
      <Top />
      <StickyTop />
      <Heads />
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <span>Loading products...</span>
        </div>
      ) : (
        <CategoryProduct products={products} />
      )}
      <Subscribe />
      <Footer />
      <ScrollUpBtn />
    </div>
  );
};

export default Search;
